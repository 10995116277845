// Variables

// Enable shadows
$enable-shadows: false;

// Fonts
$font-family-base: 'monospace', Helvetica, sans-serif;
$font-weight-boldest: 800 !default;
$font-weight-bold: 600 !default;
$font-weight-semi-bold: 500 !default;
$font-weight-normal: 300 !default;
$font-weight-thin: 200 !default;
$font-weight-base: $font-weight-normal !default;

// Typography
$font-size-initial: 15px !default;
$font-size-base: 1rem !default;
$font-size-lg: 1.25rem !default;
$font-size-sm: 0.9rem !default;
$font-size-ssm: 0.7rem !default;
$font-size-mini: 0.75rem !default;

// Headlines
$headline-large: 1.875rem !default;
$headline-medium: 1.25rem !default;
$headline-small: 1rem !default;
$headline-font-weight: $font-weight-semi-bold !default;

// Color system
$white: #ffffff !default;
$gray-100: #f4f6f9 !default;
$gray-200: #e2e3e5 !default;
$gray-300: #d0d4d9 !default;
$gray-400: #b0b8c1 !default;
$gray-500: #8a8d92 !default;
$gray-600: #6b6e70 !default;
$gray-700: #4b4f56 !default;
$gray-800: #2d2f36 !default;
$gray-900: #1f2023 !default;
$black: #000000 !default;

$blue-light: #0070d2 !default;
$blue-dark: #005fb2 !default;
$green: #2e9b4f !default;
$orange: #f7b500 !default;
$red: #b51b1a !default;
$yellow: #f7c300 !default;
$cyan: #00b3e3 !default;
$default: #000000 !default;
$dark-gray: #6b6e70 !default;
$middle-gray: #d0d4d9 !default;
$light-gray: #f4f6f9 !default;
$base-dark-gray:    #6b859e !default;
$base-light-gray:   #F7F8FB !default;
$base-middle-gray:  #C7D0D9 !default;
$violet:            #006cb5 !default;

$theme-colors: (
        primary: $blue-light,
        secondary: $middle-gray,
        secondary-red: $red,
        secondary-yellow: $yellow,
        secondary-cyan: $cyan,
        success: $green,
        info: $blue-light,
        warning: $orange,
        danger: $red,
        primary-hover: $blue-dark,
        dark: $gray-700,
        default: $default,
        dark-gray: $dark-gray,
        middle-gray: $middle-gray,
        light-gray: $light-gray,
        white: $white,
);

$body-bg: $light-gray !default;
$icon-color: $default !default;
$text-color: $default !default;
$text-muted: $dark-gray !default;
$widget-shadow: 4px 4px 16px 0 rgba(77, 83, 224, 0.2) !default;

// Components
$layout-padding: 24px 24px 68px !default;
$content-padding: 24px !default;
$content-padding-sm: 10px !default;
$main-margin: 24px !default;

// Buttons
$button-padding: 8px 32px !default;
$border-radius: 8px !default;

// Navbar
$navbar-margin: 24px !default;
$navbar-bg: $body-bg !default;
$navbar-height: 45px !default;
$navbar-padding: 24px 24px 8px !default;

// Sidebar
$sidebar-width-open: 240px !default;
$sidebar-width-closed: 50px !default;
$sidebar-transition-time: 0.2s !default;

$sidebar-item-padding: 4px 9px !default;
$sidebar-sub-item-padding: 8px 40px !default;
$sidebar-color: $white !default;

// Widgets
$widget-padding-vertical: 24px !default;
$widget-padding-horizontal: 32px !default;
$widget-bg-color: $white !default;
$widget-border-radius: 8px !default;

// Forms
$input-focus-box-shadow: none !default;

// Fonction pour accéder aux couleurs du thème
@function theme-color($color-name) {
  @return map-get($theme-colors, $color-name);
}

// Global Styles
body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  color: $text-color;
  background-color: $body-bg;
  margin: 0;
  padding: 0;
}

// Exemples de styles
.header {
  color: theme-color(white);
  padding: 15px;
  text-align: center;
}

.header h1 {
  margin: 0;
  font-size: $headline-large;
  font-weight: $headline-font-weight;
}

.button {
  padding: $button-padding;
  border-radius: $border-radius;
  background-color: theme-color(primary);
  color: theme-color(white);
  border: none;
  cursor: pointer;

  &:hover {
    background-color: theme-color(primary-hover);
  }
}

.navbar {
  background-color: theme-color(light-gray);
  height: $navbar-height;
  padding: $navbar-padding;
  margin-bottom: $navbar-margin;
}

.sidebar {
  width: $sidebar-width-open;
  background-color: theme-color(white);
  transition: width $sidebar-transition-time;
}

.sidebar.closed {
  width: $sidebar-width-closed;
}

.widget {
  background-color: theme-color(white);
  border: 1px solid theme-color(dark-gray);
  border-radius: $widget-border-radius;
  padding: $widget-padding-vertical $widget-padding-horizontal;
  box-shadow: $widget-shadow;
}
