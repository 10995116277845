@import "../../styles/helpers";

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: $font-size-ssm;
  color: $red;
}

.label-input{
  font-size: $font-size-sm;
  margin-bottom: 0.15rem;
  margin-left: 0.1rem;
}