.auth-page {
  height: 100vh;

  .left-column {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: theme-color(light-gray);
    width: 50%;
  }

  .right-column {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: theme-color(white);
    width: 50%;

    @media (max-width: breakpoint-max(md)) {
      display: none;
    }
  }
}

.widget-auth {
  width: 448px;
  box-shadow: 4px 4px 16px 0 rgba(77, 83, 224, 0.2);

  @media (max-width: breakpoint-max(xs)) {
    width: 100vw;
  }

  .auth-header {
    font-size: $headline-large;
    font-weight: $headline-font-weight;
    color: theme-color(dark-gray);
  }

  .logo-block {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin-left: 12px;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      color: theme-color(default);
    }
  }

  a {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    color: theme-color(primary);
  }
}

.auth-info {
  padding: 18px 21px;
  border-radius: 0.5rem;
  background: theme-color(light-gray);
  color: theme-color(dark-gray);
}

.form-control {
  color: theme-color(dark-gray);
}

.dividing-line {
  display: flex;
  flex-direction: row;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  color: theme-color(dark-gray);

  &:before,
  &:after {
    content: "";
    flex: 1;
    border-bottom: 1px solid theme-color(middle-gray);
    margin: auto;
  }
}

.social-label {
  margin-right: 1rem;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  color: theme-color(dark-gray);
}

.socials {
  a {
    margin: 0 0.5rem;
    color: theme-color(primary);

    &:hover {
      color: theme-color(primary-hover);
    }
  }
}
