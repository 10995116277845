.custom-card {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 20px;
  padding-left: 0px;
  width: 102%;
}

.small-modal {
  min-width: 500px;
  background-color: white;
  position: relative;
  /* ou absolute ou fixed */
  z-index: 9999;
}

.modal-content {
  padding: 10px;
}

.NDF-Input {
  width: 95% !important;
}

.simulation-container {
  position: relative;
  width: 200px;
  height: 100px;
}

.simulation-image {
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 1;
  width: 75px;
  height: 75px;
}

.simulation-details {
  position: relative;
  padding-left: 90px;
  padding-top: 15px;
}

.simulation-header {
  font-size: 11px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.simulation-price {
  margin-left: 5px;
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.checkBox-ndf {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
}

.ancre-details {
  position: absolute;
  bottom: 5px;
  right: 5px;
  text-decoration: underline;
  color: blue;
  z-index: 1;
}

.close {
  color: #aaa;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}

.text-simulation-calcul {
  text-align: center;
  margin: 10px;
  font-size: 28px;
  font-weight: bold;
  color: #333;
}


