// src/styles/_variables.scss
$widget-padding-sm: 8px;
$widget-padding-md: 16px;
$widget-padding-lg: 24px;
$grid-padding: 12px;
$grid-row-padding: 16px;
$button-padding: 8px 16px;
$font-family-base: 'SFS, Arial, sans-serif', sans-serif;
$font-size-base: 0.9rem;
$font-size-sm: 0.850rem;
$font-size-lg: 1.1rem;
$font-size-mini: 0.55rem;
$text-color: #333;
$body-bg: #f8f9fa;
$white: #fff;
$default: #000;
$base-light-gray: #e9ecef;
$base-middle-gray: #dee2e6;
$base-dark-gray: #6c757d;
$violet: #5f27cd;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-thin: 100;
$headline-large: 2rem;
$headline-medium: 1.5rem;
$headline-small: 1.25rem;
$pagination-border-width: 1px;
$pagination-border-color: #ddd;
$pagination-hover-border-color: #ccc;
$pagination-disabled-color: #bbb;
$pagination-disabled-bg: #f5f5f5;
$pagination-disabled-border-color: #ddd;
$widget-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
$breakpoint-max-md: 768px;
$breakpoint-max-sm: 576px;
$breakpoint-max-xs: 480px;
$nav-tabs-border-width: 2px;
$nav-tabs-border-radius: 4px;
$border-radius: 4px; // Assurez-vous de définir cette variable si elle est utilisée

.navbar {
  padding: $navbar-padding;
  border: none;
  background: $body-bg;

  .nav-link {
    padding: 0;
  }

  .input-group {
    width: 245px;
    height: 45px;

    .form-control {
      font-size: $font-size-sm;
      font-weight: $font-weight-normal;

      &::placeholder {
        color: theme-color(middle-gray); // Utilisation d'une couleur thématique
      }
    }
  }
}

///////// Widget //////////
.widget-p-none {
  padding: 0;
}

.widget-p-sm {
  padding: $widget-padding-sm; // Variable pour le padding small
}

.widget-p-md {
  padding: $widget-padding-md; // Variable pour le padding medium
}

.widget-p-lg {
  padding: $widget-padding-lg; // Variable pour le padding large
}

///////// Breadcrumbs //////////
.breadcrumb {
  margin: 8px 0 0;
  padding: 0;
  color: $text-color;
  background-color: transparent;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;

  a {
    color: theme-color(dark-gray); // Utilisation d'une couleur thématique
  }
}

///////// Grid //////////
.pl-grid-col {
  padding-left: $grid-padding;
}

.pr-grid-col {
  padding-right: $grid-padding;
}

.gutter > [class*='col-'] {
  padding-right: $grid-padding;
  padding-left: $grid-padding;
}

.pr-grid-row {
  padding-right: $grid-row-padding;
}

.pl-grid-row {
  padding-left: $grid-row-padding;
}

///////// Buttons //////////
.btn {
  padding: $button-padding;
  font-size: $font-size-sm;
  font-weight: $font-weight-semi-bold;

  &.btn-success,
  &.btn-info,
  &.btn-warning,
  &.btn-danger {
    color: $white;
  }

  &:hover {
    background-color: theme-color(button-hover-bg); // Couleur de fond au survol
  }
}

///////// Progress Bar //////////
.progress-xs {
  height: 6px;
}

///////// Dropdowns //////////
.dropdown-toggle {
  padding: 6px 12px 6px 6px;
  border: 1px solid theme-color(middle-gray); // Couleur de la bordure
  border-radius: $border-radius;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  color: theme-color(dark-gray); // Couleur du texte
  background-color: $white;

  &:hover {
    background-color: theme-color(light-gray); // Couleur de fond au survol
  }

  &:focus {
    background-color: $white;
  }
}

.show > .btn-secondary.dropdown-toggle {
  color: theme-color(default);
  background-color: theme-color(light-gray);

  &:focus,
  &:active {
    background-color: theme-color(light-gray);
  }
}

.dropdown-menu {
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  min-width: 100%;
}

.navbar-dropdown-toggle {
  color: $text-color;
  border: none;
}

.navbar-dropdown {
  margin-top: 12px;
  padding: 24px 16px;
  box-shadow: $widget-shadow;
  border: none;

  .dropdown-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 13px 16px;
    border-radius: $border-radius;

    &:hover {
      background-color: theme-color(light-gray); // Couleur de fond au survol
    }

    span {
      margin-left: 8px;
      font-size: $font-size-base;
      font-weight: $font-weight-semi-bold;
      color: $text-color;
    }

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .logout-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    padding: 8px 16px;
  }
}

.profile-dropdown {
  margin-left: -65px; /* Ajuste la position à 10px du côté gauche */
   @media (max-width: breakpoint-max(sm)) {
    left: -160px;
  }
}

///////// Headings //////////
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: $font-weight-semi-bold;
}

///////// Headlines //////////
.headline-1 {
  font-size: $headline-large;
  font-weight: $font-weight-semi-bold;
}

.headline-2 {
  font-size: $headline-medium;
  font-weight: $font-weight-normal;
}

.headline-3 {
  font-size: $headline-small;
  font-weight: $font-weight-semi-bold;
}

///////// Body fonts //////////
.body-1 {
  font-size: 1.3rem;
  font-weight: $font-weight-normal;
}

.body-2 {
  font-size: $font-size-sm;
  font-weight: $font-weight-semi-bold;
}

.body-3 {
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
}

.label {
  font-size: $font-size-mini;
  font-weight: $font-weight-normal;
}

.muted {
  color: theme-color(dark-gray); // Couleur atténuée
}

///////// Icons //////////
.eva {
  font-size: 30px !important;
}

.fa {
  font-size: 24px;
}

///////// Tables //////////
.react-bootstrap-table {
  table {
    overflow-x: auto;
    width: 100%;
  }
}

.widget-table-overflow {
  width: 100%;
  margin: 0 0 24px;

  th:first-child,
  td:first-child {
    padding-left: 8px;
  }

  th:last-child,
  td:last-child {
    padding-right: 24px;
  }

  .form-check-input {
    position: relative;
  }
}

.table-bordered th {
  background-color: theme-color(gray-300); // Couleur de fond pour les en-têtes
}

table > thead > tr > th {
  border-top: none !important;
  border-bottom-width: 1px !important;
  text-transform: uppercase;
}

.table-striped {
  thead {
    th {
      vertical-align: middle;
      border: none;
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: theme-color(light-gray); // Couleur de fond des lignes impaires
    }

    td {
      border: none;
    }
  }
}

.table-hover {
  tbody tr {
    @include hover() {
      background-color: $violet;
      td {
        color: $white;
      }
    }
  }
}

.pagination {
  justify-content: center;
  align-items: center;
}

.page-link {
  padding: 4px 12px;
  border: $pagination-border-width solid $pagination-border-color;

  &:hover {
    background-color: transparent;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 3;
    outline: none;
    box-shadow: none;
  }
}

.page-item {
  margin-left: 16px;
  margin-right: 16px;

  &:first-child {
    .page-link {
      border: none;
      margin-right: 80px;
      font-size: 36px;
      line-height: 1;

      @media (max-width: breakpoint-max(md)) {
        margin-right: 8px;
      }
    }
  }

  &:last-child {
    .page-link {
      margin-left: 80px;
      border: none;
      font-size: 36px;
      line-height: 1;

      @media (max-width: breakpoint-max(md)) {
        margin-left: 8px;
      }
    }
  }

  @media (max-width: breakpoint-max(md)) {
    margin-left: 4px;
    margin-right: 4px;
  }
}

.pagination-borderless {
  .page-item {
    .page-link {
      border: none;
      color: $pagination-disabled-color;
    }

    &.active .page-link {
      z-index: 3;
      color: $violet;
      background-color: transparent;
      border: none;
    }

    &:first-child {
      .page-link {
        color: $violet;
      }
    }

    &:last-child {
      .page-link {
        color: $violet;
      }
    }

    &.disabled .page-link {
      color: $pagination-disabled-color;
      pointer-events: none;
      cursor: auto;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border-color;
    }
  }
}

///////// Badges //////////
.badge {
  padding: 4px 8px;
  font-size: $font-size-sm;
  font-weight: $font-weight-semi-bold;
  color: $white;
  border-radius: $border-radius; // Utilisation de la variable de border-radius
}

///////// Checkboxes //////////
.checkbox {
  padding-left: 20px;
  display: flex;
  margin-bottom: 4px;

  label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0;
      margin-left: -20px;
      border: 1px solid theme-color(middle-gray); // Couleur de bordure
      border-radius: $border-radius;
      background-color: $white;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    }

    &::after {
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      top: 0;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: theme-color(dark-gray); // Couleur du texte
    }
  }
}

.checkbox input[type='checkbox'] {
  opacity: 0;
  z-index: 1;
}

.checkbox input[type='checkbox']:disabled + label::before {
  background-color: theme-color(light-gray); // Couleur de fond pour les checkboxes désactivées
  cursor: not-allowed;
}

.checkbox-primary input[type='checkbox']:checked + label::before {
  background-color: $violet;
  border-color: $violet;
}

.checkbox-primary input[type='checkbox']:checked + label::after {
  color: $white;
}

input[type='checkbox'].styled:checked + label::after {
  top: 2px;
  content: url("../components/Icons/checkmark.svg");
}

input[type='checkbox'] .styled:checked + label::before {
  color: $white;
}

input[type='checkbox'] .styled:checked + label::after {
  color: $white;
}

///////// Tabs //////////
.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;

  .nav-link {
    margin-bottom: -$nav-tabs-border-width;
    border: $nav-tabs-border-width solid transparent;
    color: theme-color(dark-gray); // Couleur du texte
    border-top-left-radius: $nav-tabs-border-radius;
    border-top-right-radius: $nav-tabs-border-radius;

    @include hover-focus() {
      border-color: theme-color(nav-tabs-link-hover-border-color); // Couleur de bordure au survol
    }

    &.disabled {
      color: theme-color(dark-gray); // Couleur des liens désactivés
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $violet;
    background-color: $white;
    border-color: transparent;
    border-bottom: 2px solid $violet;
  }

  .nav-link:hover {
    color: $violet;
    border-color: transparent;
    border-bottom: 2px solid $violet;
  }
}

///////// Alerts //////////
.alert {
  border: none;
  padding: 12px 36px 12px 16px;
  color: $white;

  .btn {
    border: none;
  }

  button.close {
    font-weight: $font-weight-thin;
    font-size: 20px;
    text-shadow: none;
    opacity: 1;
    color: $white;
    outline: none;
    padding: 0;
    position: absolute;
    top: auto;
    right: 16px;
    margin-left: 8px;

    &.alert-transparent {
      color: inherit;
      opacity: 1;
    }
  }
}

///////// Notification button //////////
.notification-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;
  border: none;
  border-radius: $border-radius; // Utilisation de la variable de border-radius
  color: $white;
}

///////// Toasts //////////
.Toastify__toast-container {
  padding: 0;
  background-color: transparent !important;
  box-shadow: none !important;
  width: auto;
}

.Toastify__toast {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.Toastify__toast-body {
  padding: 0 !important;
  box-shadow: none !important;
  width: 460px !important;
}

///////// Other //////////
.nav {
  .dropdown-toggle {
    background-color: transparent;
  }
  .btn-secondary{
    border: 1px solid #6b6e70;
    background: #ffffff;
    box-shadow: 4px 4px 16px 0 rgba(77, 83, 224, 0.2);
    color: #131415;
  }
}
/*
.nav {
  .dropdown-toggle {
    border: 1px solid #6b6e70;
    background: #ffffff;
    box-shadow: 4px 4px 16px 0 rgba(77, 83, 224, 0.2);
    color: #131415;
  }
}
*/
.dropdown-toggle::after {
  font-family: Line Awesome Free, sans-serif;
  content: "\F107";
  border: none;
  width: auto;
  height: auto;
  vertical-align: baseline;
  font-size: 85%;
  font-weight: 600;

  @media (max-width: breakpoint-max(xs)) {
    display: none;
  }
}
.btn-secondary{
  border: 1px solid #6b6e70;
  background: #ffffff !important;;
  box-shadow: 4px 4px 16px 0 rgba(77, 83, 224, 0.2);
  color: #131415 !important;
}
