/* Variables CSS pour une maintenance plus facile */
:root {
  --primary-color: #007bff;
  --primary-hover-color: #0056b3;
  --primary-active-color: #003f7f;
  --step-padding: 10px 0;
  --form-row-margin: 0 -15px;
  --form-col-padding: 0 15px;
}

/* Style pour les étapes de navigation */
.nav-steps {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.step {
  flex-grow: 1;
  text-align: center;
  padding: var(--step-padding);
  cursor: pointer;
  color: var(--primary-color);
  transition: color 0.3s ease;
}

.step.active {
  font-weight: bold;
  color: var(--primary-hover-color);
}

.step:hover {
  color: var(--primary-active-color);
}

/* Styles pour les formulaires */
.form-input {
  min-width: 1200px;
  min-height: 10000px !important;
}

/* Disposition des champs du formulaire en deux colonnes */
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin: var(--form-row-margin);
}

.form-col {
  flex: 1 0 50%;
  padding: var(--form-col-padding);
  box-sizing: border-box;
}

/* Adaptation pour les écrans plus petits */
@media (max-width: 768px) {
  .form-col {
    flex: 1 0 100%;
  }
}

/* Styles pour les champs du formulaire */
.custom-input {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-row > * {
    margin-bottom: 15px;
    margin-right: 0;
  }
}

/* styles.css */
.react-select__menu {
  border-radius: 0.25rem !important; /* Pour le menu dropdown */
}

.react-select__option {
  padding: 0.375rem 0.75rem !important; /* Pour chaque option */
}

.react-select__single-value {
  padding: 0.375rem 0.75rem !important; /* Pour la valeur sélectionnée */
}

.react-select__placeholder {
  padding: 0.375rem 0.75rem !important; /* Pour le placeholder */
}
.css-1uccc91-singleValue {
  font-size: 0.85rem !important;
  font-weight: 600 !important;
  color: #6b6e70 !important;
}

