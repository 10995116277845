.header {
  padding: 10px 20px;
  text-align: right;
}

.search-box {
  margin-right: 10px;
  width: 150px !important;
}

.search-box input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.card-body {
  padding: 0rem !important;
}

.outer-container {
  overflow-x: auto;
  overflow-y: auto;
  height: 400px;
  position: relative;
}

.container-card {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}

.person-container {
  display: flex;

}
$colors: red, blue, green, yellow, orange, purple, pink, cyan; // Définir la liste des couleurs

.initial-circle {
  
  border-radius: 10%;
  width: 60px;
  height: 60px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.image-initital {
  border-radius: 10%;
  width: 60px;
  height: 60px;
  display: flex;
}
.list-group-item {
  padding: 0.25rem 0.25rem !important;
}
.person-details {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 5px;
}

.person-date {
  color: black;
  margin-left: 15px;
}

.person-name {
  color: rgb(40, 39, 39);
  font-weight: 600;
  max-width: 100px;      /* Limite la largeur à 100px */
  white-space: nowrap;   /* Empêche le retour à la ligne */
  overflow: hidden;      /* Masque le texte qui dépasse */
  text-overflow: ellipsis; /* Ajoute '...' à la fin si le texte est trop long */
  font-size: 14px;       /* Taille de police initiale */
  display: block;
}

.person-role {
  color: gray;
  font-size: 0.7rem;
  max-width: 100px;      /* Limite la largeur à 100px */
  white-space: nowrap;   /* Empêche le retour à la ligne */
  overflow: hidden;      /* Masque le texte qui dépasse */
  text-overflow: ellipsis; /* Ajoute '...' à la fin si le texte est trop long */
  font-size: 14px;       /* Taille de police initiale */
  display: block;
}

.list-group-item {
  border-color: #ebedf2;
  background-color: #ffffff;
  color: #595d6e;
}

.list-group-item.active {
  background-color: #f8f9fa;
  /* Match Metronic active color */
  border-color: #f8f9fa;
  /* Match Metronic active color */
}

.column {
  flex: 1;
  margin: 0 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
}

.column h2 {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.Perspection-card {
  background-color: #fff !important;
  border: 0px !important;
  border-radius: 4px !important;
  padding: 10px !important;
  margin-bottom: 10px;
  cursor: pointer;
}


.card h3,
.card p {
  margin: 5px 0;
}

.card h3 {
  font-size: 16px;
  color: #333;
}

.card p {
  font-size: 14px;
  color: #666;
}

.list-group-item.custom-list-group {

  margin-bottom: 10px;
  border: none;
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  margin-right: 20px;
  font-size: 0.9rem;
  width: 500px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ece7e7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #918e8e;
}

th,
td {
  border: 1px solid #000;
  padding: 2px;
  text-align: left;
}

.table-simulation td {
  width: 20%;
}

.table-simulation td:first-child {
  width: 35%;
}

.highlight-green {
  background-color: #90EE90;
}

.highlight-yellow {
  background-color: rgb(248, 248, 10);
}

.highlight-blue {
  background-color: #ADD8E6;
}

.bold {
  font-weight: bold;
}

.table-simulation {
  font-size: 0.8rem;
  font-weight: 600;
  width: 100%;
}

.add-porspection {
  max-width: 95% !important;
  width: auto !important;
}