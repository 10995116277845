@import "../../styles/helpers";

.Toastify__toast--default {
  background: none  !important;
}

.notificationContainer {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: $font-size-sm;
  font-weight: $font-weight-base;
  color: $white;
}

.notificationIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.custom-toast {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-bottom: 0;
  padding: 0;
}

.custom-toast-body {
  padding: 0;
  width: auto;
  box-shadow: none !important;/* Adjust width if necessary */
}
