@import "../../styles/helpers";


.stepper-wrapper {
  font-family: Arial, sans-serif;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 10px;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #e0e0e0;
    width: 100%;
    top: 20px;
    z-index: 2;
    transition: border-color 0.4s ease;
  }

  &::before {
    left: -50%;
  }

  &::after {
    left: 50%;
  }

  .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #e0e0e0;
    color: #333;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.4s ease, color 0.4s ease;
  }

  .step-name {
    font-size: 14px;
    color: #666;
    text-align: center;
    transition: color 0.4s ease;
  }

  &.active {
    font-weight: bold;

    .step-counter {
      background-color: #4154f1;
      color: #fff;
      transform: scale(1.1);
    }

    .step-name {
      color: #4154f1;
      font-size:  $font-size-base;
    }

    &::after {
      border-bottom-color: #4154f1;
    }
  }

  &.completed {
    .step-counter {
      background-color: $red;
      color: #fff;
    }

    &::after {
      border-bottom-color: $red;
    }

    .step-name {
      color: $red;
    }
  }

  &:first-child::before,
  &:last-child::after {
    content: none;
  }
}
