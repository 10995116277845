.button-container {
    display: flex;
    justify-content: end;
  }
  
  .button {
    width: 32px;
    height: 32px;
    display: flex;
    border: none;
    background-color: #8e8e8e !important;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    font-size: 16px;
  }

  .custom-checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  .smaller-row td {
    padding: 0.25rem; /* Adjust padding to make rows smaller */
  }
  .pagination .page-item:first-child .page-link {
    font-size: 20px;
    margin-right: 1px;
  }
  
  /* Custom CSS to hide the last page link */
  .pagination .page-item:last-child .page-link {
    font-size: 20px;
    margin-left: 1px;
  }