html {
  font-family: $font-family-base;
  font-weight: $font-weight-thin; // Réglé à 100 pour correspondre à la valeur minimale
  font-size: $font-size-initial;
}

body {
  overflow-x: hidden;
  color: $text-color;
  background: $body-bg;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0; // Ajouté pour enlever les marges par défaut du body
}

html,
body,
#app {
  height: 100%;
  margin: 0; // Assure que l'ensemble prend 100% de hauteur sans marges
}

p {
  margin-bottom: 0;
}

a,
.dropdown-item,
.btn {
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  font-family: $font-family-base;
  border-radius: $border-radius; // Utilise la variable de border-radius définie
}

a {
  color: theme-color(primary); // Définit la couleur des liens pour correspondre au thème
  text-decoration: none;

  &:hover {
    color: theme-color(primary-hover); // Couleur de survol des liens
  }
}

.dropdown-item {
  background-color: theme-color(white); // Couleur de fond pour les éléments de menu déroulant

  &:hover {
    background-color: theme-color(light-gray); // Changement de couleur au survol
  }
}

.btn {
  background-color: theme-color(primary); // Couleur de fond pour les boutons
  color: theme-color(white);
  border: none; // Enlève la bordure des boutons
  padding: $button-padding; // Utilise la variable de padding des boutons
  border-radius: $border-radius; // Utilise la variable de border-radius

  &:hover {
    background-color: theme-color(primary-hover); // Changement de couleur au survol
  }
}

th {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: theme-color(dark-gray); // Couleur des titres de colonnes dans les tables
}

td {
  color: theme-color(default); // Couleur du texte des cellules de table
}

.table td {
  vertical-align: middle;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

.form-control {
  font-size: $font-size-sm; // Utilisation de la variable de taille de police
  font-weight: $font-weight-semi-bold; // Réglage du poids de la police pour les champs de formulaire
}
