.contract-details {
  max-width: 800px; /* Limite la largeur du conteneur */
  margin: 0 auto; /* Centrer le conteneur sur la page */
  padding: 20px; /* Ajoute un espacement interne */
  background-color: #f8f9fa; /* Couleur de fond claire */
  border-radius: 8px; /* Coins arrondis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Légère ombre */
}

.Widget_widget__1JBrv {
  background-color: #ffffff; /* Fond blanc pour les sections */
  border: 1px solid #6b6e70; /* Bordure gris */
  border-radius: 8px; /* Coins arrondis */
  padding: 24px 32px; /* Espacement interne */
  margin-bottom: 20px; /* Espace entre les sections */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombre légère */
}

.table-title {
  font-size: 18px; /* Taille de la police pour les titres */
  margin-bottom: 15px; /* Espace sous le titre */
  color: #343a40; /* Couleur sombre pour le texte */
  display: flex; /* Flexbox pour l'alignement des icônes */
  align-items: center; /* Centrer verticalement */
}

.table-title i {
  margin-right: 8px; /* Espace entre l'icône et le texte */
  color: #007bff; /* Couleur de l'icône */
}

.table td {
  vertical-align: middle; /* Aligner verticalement le contenu des cellules */
  padding: 12px; /* Espacement interne dans les cellules */
}

.table a {
  color: #007bff; /* Couleur du lien */
  text-decoration: none; /* Supprimer le soulignement */
}

.table a:hover {
  text-decoration: underline; /* Soulignement au survol */
}

.download-section {
  display: flex; /* Utiliser flexbox pour centrer les éléments */
  justify-content: center; /* Centre horizontalement */
  gap: 40px; /* Espace entre les éléments */
  margin-top: 20px; /* Espace au-dessus */
}

.download-section a {
  text-align: center; /* Centre le texte sous l'icône */
  color: #343a40; /* Couleur sombre pour le texte */
}

.download-section a p {
  margin-top: 8px; /* Espace au-dessus du texte */
  font-size: 14px; /* Taille de police pour le texte */
}

.download-section svg {
  color: #e63946; /* Couleur rouge pour les icônes */
  transition: transform 0.2s; /* Transition pour un effet d'agrandissement */
}

.download-section a:hover svg {
  transform: scale(1.1); /* Agrandir l'icône au survol */
}
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-container > * {
  flex: 1;
  margin: 0 10px; /* Ajustez cette valeur pour l'espacement souhaité */
}

.flex-container > span {
  flex: 0 1 auto; /* Pour que le texte ne prenne pas trop de place */
}
