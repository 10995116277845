
.environment-square {
  width: 230px;
  height: 200px;
  background-color: #cccccc7b; /* Couleur de fond des hublots */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px; /* Pour rendre les coins arrondis */
  font-size: 20px;
  font-weight: 600;
  justify-content: center; /* Center items vertically */
  text-align: center;
  flex-direction: column; /* Display items vertically */
  .company-logo {
    font-size: 80px; /* Adjust the size of the company logo */
  }

  &.add-environment-btn {
    width: 100px;
    height: 100px;
    font-size: 2rem;
    background-color: #007bff; /* Couleur de base des hublots */
    color: white;
    cursor: pointer;
    border-radius: 50%; /* Pour rendre le carré un cercle */

    transition: background-color 0.3s; /* Animation de transition */
  }
}
.environment-square:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8); 
  background-color: #3231317b;
}
.select-environment-background {
  background-image: url('../../assets/hero-bg.png');
  /* Other background properties like size, position, repeat, etc. can be added here */
}
.environment-square {
  position: relative;
  cursor: pointer;
}

.delete-icon{
  position: absolute;
  bottom: 10px;
  right: 39px;
  color: red;
  border-radius: 50%;
  cursor: pointer;
}
.delete-icon:hover{
  color: #ccccccb0; 
  background-color: red;
}
.edit-icon {
  position: absolute;
  bottom: 10px;
  right: 15px;
  color: green;
  border-radius: 50%;
  cursor: pointer;
}
.edit-icon:hover{
  color: #ccccccb0; 
  background-color: green;
}

  