// Styles globaux pour le modal
.modal-header {
  .row {
    .col-md-3 {
      text-align: center;

      // Style de l'image
      div {
        width: 100px;
        height: 100px;
        border-radius: 8px;
        overflow: hidden;
        margin: auto;
        border: 2px solid #ddd; // Bordure autour de la photo

        // Texte si l'image est indisponible
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f8f9fa;
          color: #6c757d;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .col-md-9 {
      text-align: center;

      h3 {
        margin-top: 1rem;
        font-size: 1.5rem;
      }
    }
  }
}

// Styles pour le modal body
.modal-body {
  .row {
    .col-md-6 {
      // FormGroup styles
      .form-group {
        label {
          font-size: 0.9rem;
          color: #6c757d;
          font-weight: bold;
        }

        div {
          margin-top: 0.25rem;
        }
      }
    }
  }
}

// Styles pour le modal footer
.modal-footer {
  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .col {
      text-align: center;

      button {
        &.btn-sm {
          padding: 0.5rem 1rem;

          i {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  button {
    &.btn-secondary {
      margin-left: auto;
    }
  }
}
