
.cra-table td , .cra-table th{
    padding: 0.4rem !important;
}
.weekend {
    background-color: #92CDDC !important;
  }
  .Notweekend {
    background-color: #FFFF99 !important;
  }
.cra_input{
    width: 1.2rem;
}
.cra_input:disabled {
  background-color: #e9ecef; /* Light grey background */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}
