.css-yk16xz-control
 {
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  line-height: 1.5 !important;
  color: #4b4f56 !important;
  background-clip: padding-box !important;
  border: 1px solid #b0b8c1 !important;
  border-radius: 8px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.select-custom{
  position: relative;
  width: 50%;
  margin-top: 0.25rem
}
.select-custom{
  top: 30% !important;
}
.css-tlfecz-indicatorContainer {
  display: none !important;
}
